import React from "react";
import Image from "next/image";
import { useRouter } from 'next/router';



const BasicInformation = ({
  
}) => {
    const router = useRouter()
  return (
    <>
      <div className="flex justify-center pt-8">
        <Image
          height="89"
          width="200"
          className="h-12 my-auto cursor-pointer"
          src="/icons/logo.svg"
          alt="Logo de cuánto está el dólar"
          onClick={() => router.push("/")}
        />
      </div>
      <div className="pt-4 text-center ">
        <h1 className="text-[2.1rem] font-bold ">Cuantoestaeldolar.pe</h1>
      </div>
      <p className="m-4 text-2xl text-center leading-7">
        Somos una plataforma que tiene como propósito democratizar el precio del
        dólar en el Perú, su información es actualizada a diario.
      </p>
      <div className="container mx-auto  grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-1 gap-4 mb-5">
        <div className="flex box-border border-2 border-lgray rounded-lg h-[11.75rem] ">
            <div className=" mt-5 pt-5 ml-2">
                <Image src="/img/buy-dolar.svg" width={127} height={148} alt="imagen de compra dolar"/>
            </div>
          <div className="pt-6 pr-1.5 ml-6 flex-2 align-middle w-1/2 ">
            <div className="">
                <h2 className="text-2xl font-bold">¿Qué es compra?</h2>
            </div>
            <div className="pt-2 text-lg leading-5 ">
              Es cuando una casa de cambio te compra tus dólares.
            </div>
          </div>
        </div>
        <div className="flex box-border border-2 border-lgray rounded-lg h-[11.75rem]">
            <div className = " mt-4 ml-4">
            <Image src="/img/sale-dolar.svg" width={105} height={145} alt="imagen venta dolar"/>
            </div>
          <div className="pt-6 pr-1.5 ml-8 flex-2 align-middle w-1/2">
            <div className="">
                <h2 className="text-2xl font-bold">¿Qué es venta?</h2>
            </div>
            <div className="pt-2 text-lg leading-5 ">
            Es cuando una casa de cambio te vende sus dólares.
            </div>
          </div>
        </div>
      </div>
      {/* <BlockYouTube videos={videos}/>  */}
      <div className="md:mx-16">
        <div className="container pt-6 pb-2 grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-1 gap-4">
          <div className="flex">
            <div className="flex-2 align-middle">
              <div className="pt-8">
                  <h2 className="text-2xl font-bold">¿Qué es el dólar?</h2>
              </div>
              <div className="pt-6">
                  <p className="text-lg leading-5 pr-8">
                      Es la moneda oficial de los Estados Unidos de América, cuya
                      economía es una de las más importantes a las que el Perú está
                      asociada.
                  </p>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className=" align-middle">
              <div className="pt-4">
                  <h2 className="text-2xl font-bold">Sobre el dólar en América</h2>
              </div>
              <div className="pt-6">
                  <p className="text-lg leading-5 pr-8">
                      El dólar americano tiene un gran impacto en la economía mundial y
                      se utiliza en las transacciones internacionales de todo el mundo,
                      además es la divisa más utilizada por los turistas.
                  </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container  pt-1 pb-6 grid grid-cols-1 gap-4">
          <div className="flex">
            <div className=" align-middle">
              <div className="pt-4">
                  <h2 className="text-2xl font-bold">El dólar en el Perú</h2>
              </div>
              <div className="pt-6">
                  <p className="text-lg leading-5 pr-8">
                      El precio del dólar en el Perú, puede verse afectado por
                      intervenciones del Banco Central de Reserva (BCR), a fin de
                      estabilizar la cotización o el tipo de cambio del sol peruano.
                      Asimismo, por diversos indicadores como el crecimiento del
                      Producto Bruto Interno (PBI), el comportamiento de las
                      exportaciones peruanas, la situación geopolítica internacional,
                      etc.
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInformation;
