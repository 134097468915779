import { Envivo } from "@framework/types/envivo";
import { FC, useEffect, useState } from "react";
import DolarNew from "../icons/DolarNew";
import s from './CardEnVivo.module.css'
import { dateSpanishShort } from "@framework/lib/dateFormat";
import Image from "next/image";
import SunatNew from "../icons/SunatNew";
import AdsSlotRepeatable from "@components/AdsSlotRepeatable/AdsSlotRepeatable";


export interface CardEnVivoProps {
    dataEnVivo: Envivo[] | null
}

const CardEnVivo: FC<CardEnVivoProps> = ({
    dataEnVivo
}) => {
    const [dateLocal, setdateLocal] = useState('')

    const date = new Date();
    const formatDate = date.toLocaleDateString('es-ES', { weekday: 'long', month: 'long', day: 'numeric' });
    useEffect(() => {
        setdateLocal(dateSpanishShort(new Date));
    }, [])

    const convertCurrency = (value: string) => {
        return Math.floor(parseFloat(value)* 1000) / 1000;
    }
    return (
        <>
            {dataEnVivo?.map(({ compra, venta, time, title, embed, type }, index) => (
                <div key={index}>
                    <div className="flex">
                        <div className={`block md:flex md:justify-between w-full py-7 px-3 ${(index % 2) == 0 ? 'bg-gray_ced-400' : 'bg-white'}`}>
                            <div className="block ">
                                <div className="flex h-3">
                                    <span className={`${s.data__spam__time}`}></span>
                                    <p className={`${s.data__time}`}>{time}</p>
                                </div>
                                <div className={`${s.data__content}`}>
                                    <div className="block">
                                        <h2 className={`${s.data__content__title} ${s.data__title}`}>{title}</h2>
                                        <p className={`${s.data__date}`}>Tipo de cambio del {formatDate}</p>
                                    </div>
                                    {compra && venta && (
                                        <div className="flex">
                                            <div className="flex items-center mt-3">
                                                {title === 'SUNAT' && (<SunatNew width={25} height={28} />)}
                                                {title !== 'SUNAT' && (<DolarNew width={25} height={28} />)}
                                            </div>
                                            <p className={`${s.data__current}`}>Compra: {convertCurrency(compra)}</p>
                                            <p className={`${s.data__current}`}>Venta: {convertCurrency(venta)}</p>
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                            <div className={`md:w-1/4 ${type === 'Banco de la Nación' ? 'h-12' : 'h-12 '} ${s.data__icon}`}>
                                {embed && embed.trim() !== '' && embed !== '#' && (
                                    <Image src={embed} width={'143'} height={'42'} alt="logo casa de cambio" />
                                )}
                            </div>
                        </div>
                    </div>
                    {(index + 1) % 6 === 0 && (
                        <AdsSlotRepeatable div='cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive' className="billboard-height text-center" />
                    )}
                </div>
            ))}
        </>
    );
};

export default CardEnVivo;