import { FC } from "react";

type Props = {
    width?: number;
    height?: number;
    fill?: string;
};
const SunatNew2:FC <Props> = ({ width, height, fill, ...Props}) => {
    return (
        <svg 
            width={width ? width : '32'} 
            height={height ? height : '32'} 
            viewBox="0 0 32 32" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...Props}
        >
            <circle cx="16" cy="16" r="15.5" stroke="#8E8E8E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.8655 19.2098C21.9619 18.6743 22.0674 18.1407 22.1548 17.6034C22.364 16.3093 21.6726 15.1403 20.4026 14.8499C19.3545 14.6103 18.2683 14.5359 17.202 14.3798C16.2322 14.2382 15.2279 14.1983 14.2999 13.9133C12.8789 13.4759 11.8235 12.5901 11.6561 10.971C11.5743 10.1815 11.778 9.45724 12.3148 8.88911C13.4757 7.66391 14.6765 6.47865 15.8683 5.2825C16.2632 4.88499 16.8854 4.91766 17.2857 5.31336C18.4848 6.49862 19.6966 7.67118 20.8938 8.86007C21.6853 9.64782 22.4532 10.461 23.2428 11.2487C23.9852 11.9875 24.764 12.6899 25.4845 13.4468C25.8011 13.779 26.2341 14.0331 26.3069 14.574C26.4034 15.2964 25.8593 15.6104 25.4517 16.0061C24.6311 16.8011 23.7923 17.578 22.959 18.3585C22.626 18.6707 22.2894 18.9756 21.9528 19.2842C21.9237 19.2588 21.8946 19.2334 21.8673 19.208L21.8655 19.2098Z" fill="#AE0D41"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.2872 12.1755C11.2053 12.6075 11.1162 12.9433 11.0816 13.2845C11.0343 13.7583 10.9997 14.2356 11.007 14.7112C11.0252 15.8729 12.0095 16.5426 12.943 16.7949C14.2221 17.1416 15.5358 17.1961 16.8513 17.236C17.9939 17.2705 19.0638 17.5736 20.0227 18.2017C20.9634 18.817 21.4874 19.7336 21.542 20.8227C21.5766 21.4979 21.2491 22.1549 20.7815 22.6777C20.2411 23.2839 19.6679 23.8611 19.0948 24.4384C18.4761 25.0646 17.8447 25.6799 17.2134 26.2934C16.9641 26.5366 16.2363 26.5675 15.9834 26.3242C15.2974 25.6635 14.6278 24.9847 13.9546 24.3113C13.4051 23.7631 12.8574 23.2113 12.3098 22.6614C11.4928 21.8428 10.6813 21.0187 9.86069 20.2037C9.08739 19.4377 8.305 18.6808 7.5317 17.9149C7.33883 17.7243 7.14778 17.5282 6.98221 17.3123C6.74749 17.0073 6.75477 16.559 7.03133 16.2486C7.3734 15.8638 7.75368 15.5099 8.12668 15.1541C9.15107 14.1812 10.1809 13.2137 11.2836 12.1719L11.2872 12.1755Z" fill="#0163AC"/>
        </svg>
    )
}

export default SunatNew2
