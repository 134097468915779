import React, { useState } from "react";
import ArrowNormal from "../icons/ArrowNormal";
import Link from "next/link";



const CounterfeitBanknotes = () => {
  const [expandedDolares, SetExpandedDolares] = useState(true);
  const [expandedSoles, SetExpandedSoles] = useState(true);

  const handleClickDolares = () => {
    SetExpandedDolares(!expandedDolares)
  }

  const handleClickSoles = () => {
    SetExpandedSoles(!expandedSoles)
  }

  const monedas = {
    dolares: [
      {
        denominacion: "$ 100 ",
        link: "https://cuantoestaeldolar.pe/blog/como-detectar-billetes-de-100-dolares-falsos/",
      },
      {
        denominacion: "$ 50 ",
        link: "https://cuantoestaeldolar.pe/blog/como-detectar-50-dolares-falsos/",
      },
      {
        denominacion: "$ 20 ",
        link: "https://cuantoestaeldolar.pe/blog/como-identificar-20-dolares-falsos/",
      },
    ],
    soles: [
      {
        denominacion: "S/ 100 ",
        link: "https://cuantoestaeldolar.pe/blog/6-claves-para-detectar-si-un-billete-de-s-100-es-falso/",
      },
      {
        denominacion: "S/ 50 ",
        link: "https://cuantoestaeldolar.pe/blog/7-claves-para-detectar-si-un-billete-de-s-50-soles-es-falso/",
      },
      {
        denominacion: "S/ 20 ",
        link: "#",
      },
    ],
  };
  return (
    <div className="pb-3 md:pl-4">
      <h2 className="text-black font-bold text-2xl mb-4">
        <a href={'https://blog.cuantoestaeldolar.pe/tag/billetes-falsos/'} target="_blank" rel="noopener noreferrer">
          Detecta billetes falsos »
        </a>
      </h2>
      <div
        className="flex justify-between cursor-pointer bg-gray1 align-middle pt-2 pb-[5px] pl-3.5 pr-3 rounded-md border-gray bg-Gray shadow-[0_px_1px_1px_rgba(0,0,0,0.25)] h-11"
        onClick={() => handleClickDolares()}
      >
        <h3 className="text-lg text-secondary ml-1">Dólares</h3>
        {expandedDolares ? (
          <button  aria-label="button_ArrowNormal">
            <ArrowNormal width={18} height={10} fill="#8E8E8E" />
          </button>
        ) : (
          <button className="rotate-180"  aria-label="button_ArrowNormal2">
            <ArrowNormal width={18} height={10} fill="#8E8E8E" />
          </button>
        )}
      </div>

      <div className={expandedDolares ? "content show" : "content"}>
        <ul>
          {monedas.dolares.map((billete) => (
            <li key={billete.link} className="flex font-body pt-2 pl-4 h-11 text-white bg-[#009688] rounded-lg my-0.5 text-2xl">
              <a href={billete.link} target="_blank" rel="noopener noreferrer">
                {billete.denominacion} »</a>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <div className="flex justify-between cursor-pointer bg-gray1 align-middle pt-2 pb-[5px] pl-3.5 pr-3 rounded-md border-gray bg-Gray shadow-[0_px_1px_1px_rgba(0,0,0,0.25)] h-11"
          onClick={() => handleClickSoles()}
        >
          <h3 className="text-lg text-secondary ml-1">Soles</h3>
          {expandedSoles ? (
            <button  aria-label="button_ArrowNormal3">
              <ArrowNormal width={18} height={10} fill="#8E8E8E" />
            </button>
          ) : (
            <button className="rotate-180"  aria-label="button_ArrowNormal4">
              <ArrowNormal width={18} height={10} fill="#8E8E8E" />
            </button>
          )}
        </div>
        <div className={expandedSoles ? "content show" : "content"}>
          <ul>
            {monedas.soles.map((billete) => (
              <li key={billete.link} className="flex font-body pt-2 pl-4 h-11 text-white bg-[#009688] rounded-lg my-0.5 text-2xl">
                <a href={billete.link} target="_blank" rel="noopener noreferrer">{billete.denominacion} »</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CounterfeitBanknotes;
