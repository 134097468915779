import Image from 'next/image'
import { useState } from 'react'

interface ListProps {
    item: Item, 
    initialOpen?: boolean
}
interface Item{
    icon   : string,
    width  : string,
    height : string,
    buy    : string,
    sell   : string,
    schedule : Array<any>
}
interface ExchangeHousesV2Props {
    banks: Array<any>
}

const List = ({
    item,
    initialOpen = false
}:ListProps) => {
    const { icon, width, height, buy, sell, schedule } = item
    const [isOpen, setIsOpen] = useState(initialOpen)
    const openHandler = () => setIsOpen(!isOpen)
    
    return (
        <>
            <div className='col-span-2 md:col-span-1 px-1 py-2 flex justify-between'>
                <Image src={icon} alt="interbancario" width={width} height={height}/>
                <button className="my-2" onClick={openHandler}>
                    { isOpen || initialOpen ? <span className="icon icon-arrow"/> : <span className="icon icon-arrow rotate-180"/>}
                </button>
            </div>
            <div className="hidden md:block md:col-span-2 text-center">
                <div className={`inline-block content ${ isOpen || initialOpen ? 'show' : '' }`}>
                    <div className="mt-4 text-xs text-primary">
                        {
                            schedule.map((item, index) => (
                                <div className="flex justify-between" key={index}>
                                    <p className="w-9 flex justify-end mr-4 mb-1">{item.day}</p>
                                    <p className="w-24 flex justify-start">{item.time}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="md:col-span-1 text-center">
                <p className="text-xl relative top-1/2 transform -translate-y-1/2">{ buy }</p>
            </div>
            <div className="md:col-span-1 text-center">
                <p className="text-xl relative top-1/2 transform -translate-y-1/2">{ sell }</p>
            </div>
            <div className="col-span-4 md:hidden text-center">
                <div className={`inline-block content ${ isOpen || initialOpen ? 'show' : '' }`}>
                    <div className="mt-4 text-xs text-primary">
                        {
                            schedule.map((item, index) => (
                                <div className="flex justify-between" key={index}>
                                    <p className="w-9 flex justify-end mr-4 mb-1">{item.day}</p>
                                    <p className="w-24 flex justify-start">{item.time}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const ExchangeHousesV2 = ( {
    banks
} : ExchangeHousesV2Props ) => {
    const [initialOpen, setInitialOpen] = useState(false)

    const initialOpenHandler = () => setInitialOpen(!initialOpen)

    return (
        <>
            <div className={`flex`} >
                <div>
                    <h3 className="text-secondary text-lg leading-5">Mostrar información</h3>
                </div>
                <button className="mx-6" onClick={initialOpenHandler}>
                    { initialOpen ? <span className="icon icon-arrow"/> : <span className="icon icon-arrow rotate-180"/>}
                </button>
            </div>
            <p className="align-self: center text-primary text-lg font-normal py-4">
                Precios referenciales y pueden estar sujetos a cambios por hora de publicación y políticas de gestión de clientes <br />
                Despliega para ver los horarios.
            </p>

            <div className="grid grid-cols-4 gap-1 md:grid md:grid-cols-5 md:gap-1">
                <div className="col-span-2 md:col-span-3">
                    <p className="text-black font-bold text-2xl">Cotización</p>
                </div>
                <div className="col-span-1 md:col-span-1 text-center text-primary">Compra</div>
                <div className="col-span-1 md:col-span-1 text-center text-primary">Venta</div>
            </div>
            {
                banks.map((item, index) => (
                    <div key={index} className={`grid grid-cols-4 gap-1 md:grid md:grid-cols-5 md:gap-1 ${ index % 2 ? 'bg-[#f9f9f9]' : '' }`}>
                        <List item={item} initialOpen={initialOpen} />
                    </div>
                ))
            }
        </>
    )
}

export default ExchangeHousesV2