import { Interbank } from "@framework/types/home";
import React, { FC } from "react";
import ValueQuotation, { ValueQuotationV2 } from "../ValueQuotation";
import s from "./Quotation.module.css";
import Libra from "../icons/Libra";
import Yuan from "../icons/Yuan";
import EuroNew from "../icons/EuroNew";
import DolarNew from "../icons/DolarNew";
import SunatNew from "../icons/SunatNew";
import SunatNew2 from "../icons/SunatNew2";
import Cad from "../icons/Cad";
import Link from "next/link";
import classNames from 'classnames';
import { page } from '../../../framework/types/site';

export interface QuotationProps {
  exchangeSunat ?: Interbank,
  exchangeDolar : Interbank, 
  exchangeEuro : Interbank,
  exchangeYuan : Interbank,
  exchangeLibra : Interbank,
  exchangeCanada: Interbank,
  title : string,
  currency: string,
  valueCal: number,
  page?: string
}

const Quotation :FC<QuotationProps> = ({ 
  exchangeSunat, 
  exchangeDolar, 
  exchangeEuro,
  exchangeYuan,
  exchangeLibra,
  exchangeCanada,
  title,
  currency,
  valueCal,
  page

}) => {
  return (
    <div id="converter" className={`${s.root} md:mt-0 pb-4`}>
      <div className={`${title == 'cotización' ? '' : 'md:pt-10'} `} >
        <h2 className={`text-2xl font-bold capitalize ${title == 'tipo de cambio' && page !== 'home' ? 'text-white' : 'hidden'}`}>{title}</h2>
        <div className={`flex md:w-full justify-end  ${title == 'presencial' ? 'flex' : ''}`} >
          <div className={` ${title == 'presencial' ? 'w-3/4 flex' : title == 'tipo de cambio' ? 'hidden' : 'flex mb-2 md:mb-0 '}  ${currency === '' ? 'w-3/4' : 'w-[60%]' } ` }>
            {page && (
              <h2 className={`text-2xl font-bold capitalize`}>{title}</h2>
            )}
          </div>
          <div className={`flex my-auto justify-center `}>
            <div className={title == 'presencial' ? 'text-center' : ''}>
              <div className={`${currency == 'PEN' ? 'hidden': currency == '' ? 'flex' : 'flex mx-6 md:mx-0 md:w-[160px]'} mx-1 w-16 justify-center `}>
                <h3 className={`${title == 'tipo de cambio' ? `text-lg text-gray text-center ${currency !== '' ? 'w-40 pl-8 ' : ''}`: 'text-sm text-black'} ${(title == 'cotización' && currency !== '') ? 'w-40 flex justify-center md:pl-4 ' : '' } `}>Compra</h3>
              </div>
            </div>
            <div className={`${currency == 'USD' ? 'hidden': currency == '' ? 'flex' : 'flex mx-6 md:mx-0 md:w-[160px] '} mx-2 w-16 justify-center `}> 
              <h3 className={`${title == 'tipo de cambio' ? `text-lg text-gray text-center ${currency !== '' ? 'w-40 pl-8 ' : ''}`: 'text-sm text-black'} ${(title == 'cotización' && currency !== '') ? 'w-40 flex justify-center md:pl-0 md:ml-2' : '' } `}>Venta</h3>
            </div>
          </div>
        </div>
        <div className="md:w-full mt-4">
          {exchangeSunat && (
            <div className={`flex py-1 md:my-2`}>
              <div className={`${s.row_cot} py-1  ${currency === '' ? 'w-3/4' : 'w-[60%]'}`}>
                <div className="w-10 my-auto">
                <SunatNew2 fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
                </div>
                <h3 className={`${s.text_cot} ${title == 'tipo de cambio' ? 'text-gray my-auto' : 'my-auto'}`}>Sunat</h3>
              </div>
              <div className={`${s.row_cost} ${title == 'tipo de cambio' ? 'text-white' : ''}`}>
                <ValueQuotationV2 exchangeValue={exchangeSunat} title={title} valueCal={valueCal} currency={currency} type={'sunat'}/>
              </div>
            </div>
            )}
          {exchangeDolar && (
            <div className={`bg-gray1 flex py-1 my-1 md:my-2`}>
              <div className={`${s.row_cot} py-1 ${currency === '' ? 'w-3/4' : 'w-[60%]'}`}>
                  <div className="w-10 my-auto">
                    <DolarNew fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
                  </div>
                  <h3 className={`${s.text_cot} ${title == 'tipo de cambio' ? 'text-gray my-auto' : 'my-auto'}`}>Dólar</h3>
              </div>
                <div className={`${s.row_cost} ${title == 'tipo de cambio' ? 'text-white' : ''}`}>
                <ValueQuotationV2 exchangeValue={exchangeDolar} title={title} valueCal={valueCal} currency={currency} type={'dolar'}/>
                </div>
            </div>
            )
          }
            <div className={`flex py-1 my-1 md:my-2`}>
            <div className={`${s.row_cot}  ${currency === '' ? 'w-3/4' : 'w-[60%]'}`}>
              <div className="w-10 my-auto">
                <EuroNew fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
              </div>
              <h3 className={`${s.text_cot} ${title == 'tipo de cambio' ? 'text-gray my-auto' : 'my-auto'}`}>Euro</h3>
            </div>
            <div className={`${s.row_cost} ${title == 'tipo de cambio' ? 'text-white' : ''} `}>
              <ValueQuotationV2 exchangeValue={exchangeEuro} title={title} valueCal={valueCal} currency={currency} type={'euro'}/>
            </div>
          </div>
          { (page == 'home' || page == 'cambioOnline') && (
            <div className={`flex justify-center ${page == 'cambioOnline' ? 'justify-center' : '' }`}>
              <button className='flex bg-white justify-center mt-5 md:mt-6 py-2 items-center text-[#0096A6] rounded-full font-medium text-xs md:text-xs hover:text-white border border-[#0096A6] hover:bg-[#0096A6]'>
                <Link
                    href={'/calcular'}
                    passHref
                    >
                      <p className="px-6">
                        CALCULAR MÁS DIVISAS
                      </p>
                </Link>
              </button>
              <button className='md:hidden ml-10 flex text-white justify-center mt-5 md:mt-6 items-center bg-[#0096A6] rounded-full font-medium text-xs md:text-xs hover:bg-white hover:text-[#0096A6] hover:border hover:border-[#0096A6]'>
                <Link
                    href={'/precio-del-oro'}
                    passHref
                    >
                      <p className="px-[38px]">
                        PRECIO DE ORO
                      </p>
                </Link>
              </button>
            </div>
          )}
          {(page !== 'home' && page !== 'cambioOnline') && (
            <>
              <div className={`bg-gray1 flex py-1 my-1 md:my-2`}>
                <div className={`${s.row_cot}  ${currency === '' ? 'w-3/4' : 'w-[60%]'}`}>
                  <div className="w-10 my-auto ">
                    <Yuan fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
                  </div>
                  <h3 className={`${s.text_cot} ${title == 'tipo de cambio' ? 'text-gray my-auto' : 'my-auto'}`}>Yuan</h3>
                </div>
                <div className={`${s.row_cost} ${title == 'tipo de cambio' ? 'text-white' : ''} `}>
                  <ValueQuotationV2 exchangeValue={exchangeYuan} title={title} valueCal={valueCal} currency={currency} type={'yuan'}/>
                </div>
              </div>
              <div className={`flex py-1 my-1 md:my-2`}>
                <div className={`${s.row_cot} ${currency === '' ? 'w-3/4' : 'w-[60%]'}`}>
                  <div className="w-10 my-auto ">
                    <Libra fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
                  </div>
                  <h3 className={`${s.text_cot} ${title == 'tipo de cambio' ? 'text-gray my-auto' : 'my-auto'}`}>Libra Esterlina</h3>
                </div>
                <div className={`${s.row_cost} ${title == 'tipo de cambio' ? 'text-white' : ''} `}>
                  <ValueQuotationV2 exchangeValue={exchangeLibra} title={title} valueCal={valueCal} currency={currency} type={'libra'}/>
                </div>
              </div>
              <div className={`bg-gray1 flex py-1 my-1 md:my-2`}>
                <div className={`${s.row_cot}  ${currency === '' ? 'w-3/4' : 'w-[60%]'}`}>
                  <div className="w-10 my-auto ">
                    <Cad fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
                  </div>
                  <h3 className={`${s.text_cot} ${title == 'tipo de cambio' ? 'text-gray my-auto' : 'my-auto'}`}>Dólar Canadá</h3>
                </div>
                <div className={`${s.row_cost} ${title == 'tipo de cambio' ? 'text-white' : ''} `}>
                  <ValueQuotationV2 exchangeValue={exchangeCanada} title={title} valueCal={valueCal} currency={currency} type={'canada'}/>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quotation;
