import React, { useState } from "react";
import ArrowNormal from "../icons/ArrowNormal";

function Questions() {

  const [expanded, SetExpanded] = useState(true);

  const handleClick = () => {
    SetExpanded(!expanded)
  }
  return (
    <>
      <div className="relative w-full overflow-hidden">
        
        <div className="flex bg-gray1 h-14 w-full pt-3 pl-4 align-middle justify-between" onClick={() => handleClick()}>
            <h2 className="text-2xl font-bold">Preguntas frecuentes</h2>
            {expanded ? (
              <button className="pr-7 h-full py-auto  text-black" aria-label="button_ArrowQuestion">
                <ArrowNormal width={18} height={10} fill="black"/>
              </button>
            ) : (
              <button className="pl-7 py-auto text-black rotate-180" aria-label="button_ArrowQuestion2">
                <ArrowNormal width={18} height={10} fill="black" />
              </button>
            )}
        </div>
        <div className={expanded ? "content show" : " content"} >
          <div className="md:px-16">
            <div className="pt-6 ">
                <h2 className="text-2xl font-bold">¿Qué tipos de cambio puedo conseguir?</h2>
              <p className="pt-6 text-[20px] leading-[23px]">
                El dólar interbancario es el dólar que consigues en las agencias
                bancarias.
              </p>
              <p className="pt-4 pb-6 text-[20px] leading-[23px]">
                Su precio es generalmente mayor al del dólar paralelo. Se conoce
                como dólar paralelo al dólar que consigues en las casas de
                cambio o con cambistas. En el Perú, existen casas de cambio
                fisicas y online.
              </p>
            </div>
            <div className="pt-6 ">
                <div className=" md:pr-32">
                    <h2 className="text-2xl font-bold">¿Existe un directorio de casas de cambio físicas?</h2>
                </div>
              <p className="pt-6 text-[20px] leading-[23px]">
                Si.{" "}
                <a
                  href="https://cuantoestaeldolar.pe/"
                  className="text-black-600 text-xl visited:text-black-800 font-black"
                >
                  cuantoestaeldolar.pe
                </a>{" "}
                cuenta con un directorio de casas de cambio en Lima, Cusco,
                Arequipa, Piura, Huancayo, Ayacucho, Huaral, Puno, Loreto,
                Tacna, Chiclayo y Trujillo. Puedes ver el directorio completo y
                buscar la casa de cambio que más te convenga.
              </p>
            </div>
            <div className="pt-6">
                <div className="md:pr-40">
                    <h2 className="text-2xl font-bold">¿Son seguras las casas de cambio online?</h2>
                </div>
              <p className="pt-6 text-[20px] leading-[23px]">
                SI. Las casas de cambio online suelen estar registradas en la
                Superintendencia de Banca, Seguros y AFP (SBS). Además, las
                transacciones y depósitos se realizan a través de entidades
                autorizadas por la SBS.
              </p>
              <p className="pt-6 text-[20px] leading-[23px]">
                Otras de las características de las casas de cambio online es
                que puedes realizar la transacción desde la comodidad de tu casa
                u oficina, ahorrándote tiempo que sueles perder en las colas o
                en el tráfico.
              </p>
              <p className="pt-6 text-[20px] leading-[23px]">
                Finalmente, y tal vez la característica más atractiva para los
                usuarios de las casas de cambio online es que su tipo de cambio
                es más competitivo, es decir, que obtienes un mejor tipo de
                cambio que en las agencias bancarias.
              </p>
            </div>
          </div>
        </div>
        <div className=" bg-white p-8"></div>
      </div>
    </>
  );
}

export default Questions;
