import React, { useState, FC } from "react";

export interface CalculatePercentageProps {
    page : string
    
}

const CalculatePercentage : FC<CalculatePercentageProps> = ({
    page,
    
}) => {
      const [amount, setAmount] = useState(0);
      const [total, setTotal] = useState(0);
      const [percentage, setPercentage] = useState(0);
      const [amount2, setAmount2] = useState(0);
      const [total2, setTotal2] = useState(0);
      const [percentage2, setPercentage2] = useState(0);
      const [amount3, setAmount3] = useState(0);
      const [total3, setTotal3] = useState(0);
      const [percentage3, setPercentage3] = useState(0);
      const handleChangeAmount = (event : any) => {const result = Number(event.target.value); setAmount(result)};
      const handleChangeAmount2 = (event : any) => {const result = Number(event.target.value); setAmount2(result)};
      const handleChangeAmount3 = (event : any) => {const result = Number(event.target.value); setAmount3(result)};
      const handleChangeTotal = (event : any) => {const result = Number(event.target.value); setTotal(result)};
      const handleChangeTotal2 = (event : any) => {const result = Number(event.target.value); setTotal2(result)};
      const handleChangeTotal3 = (event : any) => {const result = Number(event.target.value); setTotal3(result)};
      const handleChangePercentage = (event : any) => {const result = Number(event.target.value); setPercentage(result);};
      const handleChangePercentage2 = (event : any) => {const result = Number(event.target.value); setPercentage2(result);};
      const handleChangePercentage3 = (event : any) => {const result = Number(event.target.value); setPercentage3(result);};
      const calculateAmount = () => {
        if (total == 0){
        const result = percentage / 100 * amount
        setTotal(result);
      } else {
        setTotal(0);
        setAmount(0);
        setPercentage(0)
      }
      };
      const calculateTotal = () => {
        if (total2 == 0) {
          const result = (amount2 * 100) / percentage2
          setTotal2(result);
        } else {
          setTotal2(0);
          setAmount2(0);
          setPercentage2(0)
        }
      };
      const calculatePercentage = () => {
        if (total3 == 0) {
          const result = (amount3 * 100) / percentage3
          setTotal3(result);
        } else {
          setTotal3(0);
          setAmount3(0);
          setPercentage3(0)
        }
      };
    return (
    <div className="flex flex-col text-2xl font-bold">
      <h2 className="flex md:justify-center">Calcular Porcentaje</h2>
      <div className="md:w-full h-[auto] text-center mt-[29px] pt-[34px] grid grid-cols-1 bg-Gray calculator mb-10">
        <p className="font-normal text-lg md:text-2xl">
          Calcular una cantidad, a partir del porcentaje que representa sobre el
          total
        </p>
        <div className="block md:flex md:px-10">
          <div className="grid grid-cols-4 text-lg items-center justify-between h-[50px] m-4 md:ml-0 font-normal md:h-[40px] ">
            <p className="md:ml-16 text-left">El</p>
            <input             
              className="bg-[#FAFAFA] h-full pl-2 border-t-[1px] "
              value={amount}
              onChange={handleChangeAmount}
            />
            <p className="w-12 md:contents">% de</p>
            <input             
              className="bg-[#FAFAFA] h-full pl-2 border-t-[1px]"
              value={percentage}
              onChange={handleChangePercentage}
            />
          </div>
          <div className="flex h-[50px] items-center justify-center font-normal text-lg md:my-4 md:mr-14 md:h-[40px]">
              <p>es</p>
              <input
                className="w-[150px] ml-3 bg-[#DCEBED] h-full pl-2 border-t-[2px]"
                value={total}
                onChange={handleChangeTotal}
                disabled
              />
          </div>
        </div>
        <p className="text-xs text-third2 pt-[11px] md:px-10 md:text-left md:ml-16">
          Ejemplo: el 70% de 10 es 7
        </p>
        <div className="flex justify-center pb-[35px] pt-[28px] text-sm font-medium">
            <button 
                className="bg-[#009688] hover:bg-[#027A6F] uppercase w-[140px] h-9 px-4 rounded-3xl shadow-sm shadow-black text-sm text-white font-medium "
                onClick={calculateAmount}
            >
                calcular
            </button>
        </div>
      </div>
      <div className="md:w-full h-[auto] text-center mt-[29px] pt-[34px] grid grid-cols-1 bg-Gray calculator mb-10">
        <p className="font-normal text-lg px-8 md:text-2xl md:w-[498px] md:px-0 md:mx-auto">
          Calculo del total, a partir de la cantidad elegida y el porcentaje que
          representa sobre el total.
        </p>
        <div className="block md:flex md:mt-4 md:mx-14">
          <div className="grid grid-cols-4 text-lg items-center justify-between h-[50px]  font-normal md:mt-12 md:h-[40px] ">
            <p className="md:ml-16 ml-4 text-left">Si</p>
            <input
              className="bg-[#FAFAFA] h-full pl-2 border-t-[1px] "
              placeholder="4"
              name="value"
              value={amount2}
              onChange={handleChangeAmount2}
            />
            <p>es el</p>
            <input
              className="bg-[#FAFAFA] h-full pl-2 border-t-[1px] "
              placeholder="40"
              name="percentage"
              value={percentage2}
              onChange={handleChangePercentage2}
            />
          </div>
          <div>
            <div className="flex mb-4 justify-center">
              <p className="text-xs text-primary px-20 md:px-10">
                % de una cantidad total, esa cantidad total es
              </p>
            </div>
              <div className="flex h-[50px] justify-center font-normal text-lg md:h-[40px]">
                <input
                  className="w-[170px] ml-3 bg-[#DCEBED] h-full pl-2 border-t-[2px]"
                  placeholder="10"
                  name="result"
                  value={total2}
                  onChange={handleChangeTotal2}
                  disabled
                />
              </div>
          </div>
        </div>
            <p className="text-xs text-third2 m-4 md:px-14 md:text-left md:ml-16">
            Ejemplo: Si 4 es el 40% de una cantidad total, esa cantidad total es
            10.
            </p>
        <div className="flex justify-center pb-[35px] text-sm font-medium">
            <button 
                className="bg-[#009688] hover:bg-[#027A6F] uppercase w-[140px] h-9 px-4 rounded-3xl shadow-sm shadow-black text-sm text-white font-medium "
                onClick={() => calculateTotal()}
            >
                calcular
            </button>
        </div>
      </div>
      <div className="md:w-full h-[auto] text-center mt-[29px] pt-[34px] grid grid-cols-1 bg-Gray calculator mb-10">
        <p className="font-normal text-lg px-10 md:text-2xl">
          Como calcular el porcentaje que representa una cantidad respecto al
          total
        </p>
        <div className="block md:flex md:mx-20">
          <div className="grid grid-cols-4 text-lg items-center justify-between h-[50px] m-4 font-normal md:h-[40px] md:mx-0">
            <input
              className="bg-[#FAFAFA] h-full pl-2 border-t-[1px] "
              placeholder="3"
              name="value"
              value={amount3}
              onChange={handleChangeAmount3}
            />
            <p className="mr-8">es el</p>
              <input
                className="bg-[#DCEBED] h-full pl-2 border-t-[2px] "
                placeholder="30"
                name="percentage"
                value={total3}
                onChange={handleChangeTotal3}
                disabled
              />
              <p>de</p>
          </div>
          <div className="flex h-[50px] justify-center font-normal text-lg md:h-[40px] md:my-4">
            <input
            className="w-[170px] ml-3 bg-[#FAFAFA] h-full pl-2 border-t-[2px]"
            placeholder="10"
            name="total"
            value={percentage3}
            onChange={handleChangePercentage3}
            />
          </div>
        </div>
        <div className="block justify-between md:flex md:mx-20">
          <p className="text-xs text-third2 my-3 ">
            Ejemplo: 3 es el 30% de 10
          </p>
          <p className="text-xs text-third2 my-3 mx-10 md:mx-0">
            * Rellene el primer y tercer recuadro para obtener el porcentaje que
            representa
          </p>
        </div>
        <div className="flex justify-center pb-5 text-sm font-medium">
            <button 
                className="bg-[#009688] hover:bg-[#027A6F] uppercase w-[140px] h-9 px-4 rounded-3xl shadow-sm shadow-black text-sm text-white font-medium "
                onClick={() => calculatePercentage()}
            >
                calcular
            </button>
        </div>
      </div>
    </div>
    )
}

export default CalculatePercentage
