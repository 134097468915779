import { FC } from "react";

type Props = {
    width?: number;
    height?: number;
    fill?: string;
};
const Libra: FC<Props> = ({ width, height, fill, ...Props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...Props}
        >
            <circle cx="16" cy="16" r="15.5" stroke="#80387D" />
            <g clipPath="url(#clip0_5968_16986)">
                <path d="M15.779 17.5219H18.8168V15.2937H15.2229C14.8817 14.2357 14.1368 11.269 16.1782 10.4251C16.6516 10.2293 17.2039 10.1784 17.7795 10.2801C17.979 10.315 19.7288 10.6717 19.7288 12.3687V12.5258H22.0008V12.3687C22.0008 9.87407 20.0823 8.4207 18.1809 8.08601C17.173 7.90841 16.1759 8.00708 15.2963 8.37061C11.8308 9.80425 12.4767 13.8061 12.855 15.2929H11V17.5212H13.5001C13.6904 19.817 11.4951 22.0809 11.4719 22.1037L11.3992 22.178L12.1758 23.998L21.2141 24.0002V21.772L14.546 21.7705C15.1935 20.7064 15.8641 19.1817 15.7783 17.5219H15.779Z" fill="#80387D" />
            </g>
            <defs>
                <clipPath id="clip0_5968_16986">
                    <rect width="11" height="16" fill="white" transform="translate(11 8)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Libra
