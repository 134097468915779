import { FC } from "react";

type Props = {
    width?: number;
    height?: number;
    fill?: string;
};

const Yuan: FC<Props> = ({ width, height, fill, ...Props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...Props}
        >
            <circle cx="16" cy="16" r="15.5" stroke="#FFC24C" />
            <g clipPath="url(#clip0_5968_16980)">
                <path d="M21.7622 8.42539C21.3883 7.93644 20.7067 7.85743 20.2393 8.24859L15.4993 12.2153L10.7599 8.24859C10.2931 7.85743 9.61091 7.93644 9.23762 8.42539C8.86372 8.9137 8.93924 9.62737 9.40662 10.0185L14.4166 14.2112V14.7999H12.2499C11.6519 14.7999 11.1666 15.3076 11.1666 15.9332C11.1666 16.5588 11.6519 17.0666 12.2499 17.0666H14.4166V18.1999H12.2499C11.6519 18.1999 11.1666 18.7076 11.1666 19.3332C11.1666 19.9588 11.6519 20.4666 12.2499 20.4666H14.4166V23.8666C14.4166 24.4922 14.9019 24.9999 15.4999 24.9999C16.0979 24.9999 16.5832 24.4922 16.5832 23.8666V20.4666H18.7499C19.3479 20.4666 19.8332 19.9588 19.8332 19.3332C19.8332 18.7076 19.3479 18.1999 18.7499 18.1999H16.5832V17.0666H18.7499C19.3479 17.0666 19.8332 16.5588 19.8332 15.9332C19.8332 15.3076 19.3479 14.7999 18.7499 14.7999H16.5832V14.2119L21.5932 10.0185C22.0606 9.62737 22.1361 8.91434 21.7622 8.42539Z" fill="#FFC24C" />
            </g>
            <defs>
                <clipPath id="clip0_5968_16980">
                    <rect width="13" height="17" fill="white" transform="translate(9 8)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default Yuan;