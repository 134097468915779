import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const Copy : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : 28}
        height={height ? height : 32} 
        fill= {fill ? fill : 'none'} 
        viewBox="0 0 28 32" 
        {...props}
    >
        <g opacity="0.5">
        <path fillRule="evenodd" clipRule="evenodd" d="M3 3V22H17V3H3ZM2 0C0.895431 0 0 0.89543 0 2V23C0 24.1046 0.895431 25 2 25H18C19.1046 25 20 24.1046 20 23V2C20 0.895431 19.1046 0 18 0H2Z" fill="black"/>
        <path d="M8 9C8 7.89543 8.89543 7 10 7H26C27.1046 7 28 7.89543 28 9V30C28 31.1046 27.1046 32 26 32H10C8.89543 32 8 31.1046 8 30V9Z" fill="#FFDF4C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11 10V29H25V10H11ZM10 7C8.89543 7 8 7.89543 8 9V30C8 31.1046 8.89543 32 10 32H26C27.1046 32 28 31.1046 28 30V9C28 7.89543 27.1046 7 26 7H10Z" fill="black"/>
        </g>
    </svg>
)
export default Copy