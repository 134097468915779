import React, { FC } from "react";

export interface ExchangeEquivalentsProps {
    buy : number
}

const ExchangeEquivalents : FC<ExchangeEquivalentsProps> = ({ 
    buy 
}) => {
  const table_1 = [1, 5, 10, 50, 100, 500, 1000, 5000];
  const table_2 = [10, 50, 100, 500, 1000, 5000];
  return (
    <div>
      <p className="text-black font-bold text-2xl mb-4 md:ml-3">Equivalencias</p>
      <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 md:px-0 md:pl-3">
        <div className="border-2 border-solid rounded-xl grid">
          <p className="text-center pt-[25px] text-black text-lg">
            Dólares a soles
          </p>
          <div className="grid pt-6 pb-6 grid-cols-4 gap-3 items-center ">
            <div className="grid text-body text-center font-bold text-[42px] text-secondary">
              $
            </div>
            <div className="grid text-[18px] gap-1.5 pr-6">
              {table_1.map((dolar) => (
                <div key={dolar} className="flex justify-end">
                  <p>{dolar}</p>
                </div>
              ))}
            </div>
            <div className="grid text-[18px] gap-1.5 pr-6">
              {table_1.map((dolar) => (
                <div key={dolar} className="flex justify-start">
                  <p className="align-left">
                    {(dolar * buy).toFixed(2)}
                  </p>
                </div>
              ))}
            </div>
            <div className="grid text-body font-bold justify-center items-center text-[42px] text-secondary">
              S/
            </div>
          </div>
        </div>
        <div className="border-2 border-solid rounded-xl  grid pb-6">
          <p className="text-center pt-[25px] text-black text-lg">
            Soles a dólares
          </p>
          <div className="grid pt-6 pb-6 grid-cols-4 gap-3 items-center">
            <div className="grid text-body font-bold justify-center items-center text-[42px] text-secondary">
              S/
            </div>
            <div className="grid text-[18px] gap-1.5 pr-6">
              {table_2.map((sol) => (
                <div key={sol} className="flex justify-end ">
                  <p>{sol}</p>
                </div>
              ))}
            </div>
            <div className="grid text-[18px] gap-1.5 pr-6 ">
              {table_2.map((sol) => (
                <div key={sol} className="flex justify-start">
                  <p className="align-left">
                    {(sol / buy).toFixed(2)}
                  </p>
                </div>
              ))}
            </div>
            <div className="grid text-body font-bold justify-center items-center text-[42px] text-secondary">
              $
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeEquivalents;
