import { FC, useRef, useState } from "react";
import Clock from "../icons/Clock";
import Copy from "../icons/Copy";
import { LandingBody } from "@framework/types/site";

interface CouponCedProps {
    className?: string;
    landingBody?: LandingBody;
}

const CouponCed: FC<CouponCedProps> = ({ 
    className, 
    landingBody
}) => {
    const cuponRef = useRef(null);
    const [isCopied, setIsCopied] = useState(false);
    const handleCopyClick = () => {
      if (cuponRef.current) {
        const cuponText = landingBody?.cupon || "";
        navigator.clipboard.writeText(cuponText)
          .then(() => {
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 2000); 
          })
          .catch((error) => {
            console.error('Error al copiar el texto:', error);
          });
      }
    };
    return (
        <>
            <div className={`bg-white rounded-lg p-9 mx-4 shadow-coupon mt-8 mb-6 ${className}`}>
                <div className="flex flex-col md:flex-row md:items-center md:justify-between ">
                    <div className="flex items-center w-full md:w-1/2 mb-3">
                        <div className="flex flex-col">
                            <p className="text-2xl font-bold text-gray-900 ">Horario de atención</p>
                            {landingBody && (
                                <div className="text-2xl font-bold mb-3 w-full mt-5 flex">
                                    <div className="mt-1">
                                        <Clock width={20} height={20} fill="#8E8E8E"/>
                                    </div>
                                    <div className="block">
                                        <p className="text-xl font-normal ml-3">Lunes - Viernes: {landingBody.schedule?.["M_F"]}</p>
                                        <div className=" block md:flex ">
                                            <p className="text-xl font-normal ml-3">Sábado: {landingBody.schedule?.Saturdays }</p>
                                            <p className="text-xl font-normal ml-3">Domingo: {landingBody.schedule?.Sundays}</p>
                                        </div>
                                    </div>
                                </div>
                                )}
                        </div>
                    </div>
                    <div className="flex items-center md:w-1/2">
                        <div className="flex flex-col w-full">
                            <p className="text-2xl font-bold ">Código de cupón</p>
                                <div className="text-2xl bg-yellow_ced-100 font-bold shadow-coupon_code px-5 pb-3 pt-[12px] mt-5 mb-2 flex justify-between">
                                    <p ref={cuponRef}>{landingBody?.cupon}</p>
                                    <button onClick={handleCopyClick}>
                                        {isCopied ? <p>Copiado!</p> : <Copy />}
                                    </button> 
                                </div>
                            <p className=" text-xs text-lightGray">* Promoción válida todo el mes de julio</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CouponCed
