import { PostsBlog } from "@framework/types/home";
import Image from "next/image";
import React, { FC, useState } from "react";
import YoutubeLogo from "../icons/YoutubeLogo";
import Slider from "../Slider";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";

export interface CardBlogProps {
    posts : PostsBlog []
}

const CardBlog : FC<CardBlogProps> = ({
    posts 
}) => {
  const [dataPost, setdataPost] = useState(posts.slice(0, 6))
  const data = [
    {
      title: "Cómo abrir cuenta de Yape con DNI 2022",
      linkHref: "https://youtu.be/AON6i_gtYaA",
      linkImg: "https://i.ytimg.com/vi_webp/AON6i_gtYaA/maxresdefault.webp",
      linkAlt: "imagen de video Cómo abrir cuenta de Yape con DNI 2022"
    },
    {
    title: "Criptomonedas || ¿Cómo minar? ¿Cómo crearlas? 😱✅",
    linkHref: "https://youtu.be/AoA339XrNZE",
    linkImg: "https://i.ytimg.com/vi_webp/AoA339XrNZE/maxresdefault.webp",
    linkAlt: "imagen de video Criptomonedas"
    },
    {
      title: "Billetera Internacional Paypal 💸",
      linkHref: "https://youtu.be/2lNPnolrPJs",
      linkImg: "https://i.ytimg.com/vi_webp/2lNPnolrPJs/maxresdefault.webp",
      linkAlt: "imagen de video Billetera Internacional Paypal"
    }
  ]
  return (
    <div className="grid grid-cols-1 mx-3 md:grid-cols-3 justify-items-center mb-8">
      {dataPost?.map((post,index) => (
          <div key={index} className="max-w-sm rounded-xl overflow-hidden my-2 md:mx-2">
            <a href={post?.link} target="_blank" rel="noreferrer nofollow">
              {post?.image ? (
                <Image className="w-full rounded-b-xl" src={post?.image} width='100%' height='55%' layout='responsive' alt="img post"/>
              ) : (
                <Image src={'/img/lugares/default-lugares.svg'} alt="imagen default" height={'55%'} width={'100%'} layout='responsive'/>
                // <div className="w-full rounded-b-xl" style={{backgroundColor: "gray", height: "55%"}} />
              )}
              <p className="text-2xl mb-2 mt-4" dangerouslySetInnerHTML={{ __html: `${ post?.title }` }}/>
            </a>
          </div>
        )
      )}
      {/* <div className="grid grid-cols-1 mx-3 mt-2 md:mx-0 ">
          <div className="max-w-md rounded-xl overflow-hidden">
              <Slider page={'home'} data={data}/>
              <div className="flex justify-end py-3">
                  <YoutubeLogo width={123} height={28.01} fill={'black'}/>
              </div>
          </div>
      </div> */}
    </div>
  );
};

export default CardBlog;
