import { FC } from "react";

type Props = {
    width?: number;
    height?: number;
    fill?: string;
};
const SunatNew:FC <Props> = ({ width, height, fill, ...Props}) => {
    return (
        <svg 
            width={width ? width : '26'} 
            height={height ? height : '29'} 
            viewBox="0 0 26 29" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...Props}
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M19.9543 18.8381C20.0821 18.1283 20.2221 17.4208 20.3378 16.7085C20.6152 14.9928 19.6986 13.4432 18.0149 13.0582C16.6255 12.7405 15.1854 12.6419 13.7719 12.4349C12.4862 12.2472 11.1547 12.1943 9.92451 11.8165C8.04061 11.2366 6.64156 10.0623 6.41964 7.91589C6.31109 6.86914 6.58125 5.90903 7.29284 5.15585C8.8318 3.53159 10.4238 1.96027 12.0038 0.374512C12.5272 -0.15247 13.3522 -0.109156 13.8829 0.415419C15.4725 1.98674 17.079 3.54122 18.6662 5.11735C19.7155 6.16169 20.7334 7.23972 21.7803 8.28405C22.7645 9.26342 23.7969 10.1947 24.7521 11.1981C25.1718 11.6385 25.7459 11.9753 25.8424 12.6924C25.9702 13.6501 25.249 14.0664 24.7087 14.591C23.6208 15.645 22.5088 16.6749 21.404 17.7096C20.9626 18.1235 20.5163 18.5277 20.0701 18.9368C20.0315 18.9031 19.9929 18.8694 19.9567 18.8357L19.9543 18.8381Z" fill={fill ? fill : '#AE0D41'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.92958 9.51165C5.82103 10.0844 5.70283 10.5295 5.657 10.9819C5.59429 11.61 5.54845 12.2428 5.5581 12.8733C5.58223 14.4133 6.88721 15.3012 8.12465 15.6357C9.8204 16.0953 11.562 16.1675 13.306 16.2204C14.8208 16.2662 16.2392 16.668 17.5104 17.5006C18.7575 18.3163 19.4522 19.5315 19.5245 20.9753C19.5704 21.8705 19.1362 22.7415 18.5163 23.4346C17.7998 24.2383 17.04 25.0035 16.2802 25.7687C15.46 26.5989 14.623 27.4146 13.786 28.2279C13.4555 28.5504 12.4907 28.5913 12.1554 28.2688C11.246 27.3929 10.3583 26.493 9.46581 25.6002C8.73734 24.8735 8.01128 24.142 7.28521 23.4129C6.20215 22.3277 5.12633 21.2352 4.03844 20.1548C3.01327 19.1393 1.97604 18.1359 0.950866 17.1204C0.695176 16.8677 0.441899 16.6079 0.222392 16.3215C-0.0887775 15.9172 -0.0791289 15.3229 0.28752 14.9114C0.741008 14.4013 1.24515 13.932 1.73964 13.4604C3.09769 12.1706 4.46298 10.8881 5.92475 9.50684L5.92958 9.51165Z" fill={ fill ? fill : '#0163AC'}/>
        </svg>
    )
}

export default SunatNew