import { IChart } from '@framework/types/chart';
import React, { FC } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
    AreaChart,
    Area,
} from 'recharts';

interface ChartsVariationProps {
    data: IChart[];
}

const ChartsVariation: FC<ChartsVariationProps> = ({ data }) => {
    const chartData = data?.[0]?.price || [];
    const dataChart = chartData.map((item) => {
        const dateTime = new Date(item.dateTime);
        const formattedDate = dateTime.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        const formattedTime = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    
        return {
            Hora: `${formattedDate} ${formattedTime}`,
            Dólar: item.value,
        };
    });
    

    if (dataChart.length === 0) {
        return null; // O muestra un mensaje de carga o un componente alternativo
    }

    const minValue = Math.min(...dataChart.map((item) => item.Dólar));
    const maxValue = Math.max(...dataChart.map((item) => item.Dólar));

    const yAxisMargin = 0.1;
    const yMinValue = minValue - yAxisMargin * (maxValue - minValue);
    const yMaxValue = maxValue + yAxisMargin * (maxValue - minValue);

    return (
        <div style={{ position: 'relative', minHeight: 500, width: '100%' }}>
            <ResponsiveContainer width="100%" height={500}>
                <AreaChart data={dataChart}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: -15,
                                        bottom: 30,
                                    }}
                                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#80387D" stopOpacity={0.5} />
                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="Hora" axisLine={false} tickLine={false} tick={{ fontSize: 14 }} angle={-45} textAnchor="end" />
                    <YAxis tickFormatter={(value) => value.toFixed(3)} domain={[yMinValue, yMaxValue]} tickCount={10} tickLine={false} axisLine={false} tick={{ fontSize: 14 }} />
                    <Tooltip />
                    <Area type="basis" dataKey="Dólar" stroke="transparent" fill="url(#colorUv)" />
                </AreaChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="100%" height={500} className={`absolute top-0 left-0`}>
                <LineChart data={dataChart}
                    margin={{
                        top: 20,
                        right: 0,
                        left: -15,
                        bottom: 30,
                    }}
                >
                    <CartesianGrid strokeDasharray="0" vertical={false} />
                    <XAxis dataKey="Hora" axisLine={false} tickLine={false} tick={{ fontSize: 14 }} angle={-45} textAnchor="end" />
                    <YAxis tickFormatter={(value) => value.toFixed(3)} domain={[yMinValue, yMaxValue]} tickCount={10} tickLine={false} axisLine={false} tick={{ fontSize: 14 }} />
                    <Tooltip />
                    <Line type="basis" dataKey="Dólar" stroke={'#80387D'} strokeWidth={2} dot={false} activeDot={{ r: 5 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ChartsVariation;
