import { FC } from "react";

type Props = {
    width?: number;
    height?: number;
    fill?: string;
};
const EuroNew: FC<Props> = ({width, height, fill, ...Props}) => {
    return (
        <svg 
            width="32" 
            height="32" 
            viewBox="0 0 32 32" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...Props}
        >
            <path d="M17.5458 21.5154C17.911 21.5154 18.2653 21.4868 18.6086 21.4297C18.9592 21.3726 19.2915 21.2869 19.6056 21.1727L20 23.6787C19.5691 23.7787 19.1162 23.8572 18.6414 23.9143C18.1667 23.9714 17.6846 24 17.1952 24C16.2749 24 15.4276 23.8751 14.6534 23.6252C13.8792 23.3753 13.2072 23.0004 12.6375 22.5007C12.075 21.9938 11.6368 21.369 11.3227 20.6265C11.0086 19.8768 10.8516 19.0022 10.8516 18.0027V14.3079C10.8516 13.2655 11.0086 12.3516 11.3227 11.5663C11.6441 10.7809 12.0969 10.1241 12.6813 9.59572C13.2656 9.06738 13.9595 8.67113 14.7629 8.40696C15.5664 8.13565 16.4575 8 17.4363 8C17.8672 8 18.2945 8.03213 18.7181 8.09639C19.1491 8.1535 19.5764 8.23204 20 8.33199L19.6056 10.8487C19.3207 10.7416 18.9993 10.656 18.6414 10.5917C18.2908 10.5274 17.9439 10.4953 17.6006 10.4953C17.0601 10.4953 16.5744 10.5703 16.1434 10.7202C15.7125 10.863 15.3473 11.0879 15.0478 11.3949C14.7556 11.6948 14.5329 12.0839 14.3795 12.5622C14.2261 13.0406 14.1494 13.6154 14.1494 14.2865V18.0027C14.1494 18.6595 14.2297 19.2164 14.3904 19.6734C14.5511 20.1232 14.7812 20.4837 15.0807 20.755C15.3801 21.0192 15.738 21.212 16.1544 21.3333C16.5707 21.4547 17.0345 21.5154 17.5458 21.5154ZM17.8088 13.7082V15.2932H9V13.7082H17.8088ZM17.8088 16.6533V18.2383H9V16.6533H17.8088Z" fill="#33B6FF"/>
            <circle cx="16" cy="16" r="15.5" stroke="#33B6FF"/>
            </svg>
    );
};

export default EuroNew;



